@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Regular.woff") format("woff"), url("../public/fonts/OpenSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Italic.woff") format("woff"), url("../public/fonts/OpenSans-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Light.woff") format("woff"), url("../public/fonts/OpenSans-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Light-Italic.woff") format("woff"), url("../public/fonts/OpenSans-Light-Italic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Medium.woff") format("woff"), url("../public/fonts/OpenSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Medium-Italic.woff") format("woff"), url("../public/fonts/OpenSans-Medium-Italic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-SemiBold.woff") format("woff"), url("../public/fonts/OpenSans-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-SemiBold-Italic.woff") format("woff"), url("../public/fonts/OpenSans-SemiBold-Italic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Bold.woff") format("woff"), url("../public/fonts/OpenSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Bold-Italic.woff") format("woff"), url("../public/fonts/OpenSans-Bold-Italic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-ExtraBold.woff") format("woff"), url("../public/fonts/OpenSans-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-ExtraBold-Italic.woff") format("woff"), url("../public/fonts/OpenSans-ExtraBold-Italic.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

html {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  position: relative;
}

button,
input,
textarea {
  font-family: inherit;
}